import { Box, Text, Badge } from "@mantine/core";
import EperuveTypeHeader from "./compenents/EpreuveTypeHeader";

const EpreuveNombreManquant = ({ epreuve }) => {
    return (
        <Box>
            <h1>organisation</h1>
            <Box>
                {
                    console.log(epreuve)
                }
                <EperuveTypeHeader epreuve={epreuve} />
                <Box my={10}>
                    <Text>Consigne</Text>
                    {epreuve?.consigne?.map((item, index) => {
                        // Parse the JSON string into an object
                        const parsedItem = JSON.parse(item);
                        return (
                            <Badge
                                key={index}
                                color="gray"
                                className="text-dark me-3"
                                variant="outline"
                            >
                                {parsedItem.value}
                            </Badge>
                        );
                    })}
                </Box>
                <Box mt={15}>
                    <Text>La correction</Text>
                    {epreuve?.correction_dp?.map((choice, index) => (
                        <Badge
                            key={index}
                            color="gray"
                            className="text-dark me-3"
                            variant="outline"
                        >
                            {choice}
                        </Badge>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

export default EpreuveNombreManquant;