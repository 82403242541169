import React from 'react';
import { Box, TextInput, Group, ActionIcon, Divider } from '@mantine/core';
import { X } from 'tabler-icons-react';

function NumberDictationEpreuve({ form, index, onRemove, generateAdjustedChoices }) {
    return (
        <Box key={index}>
            <TextInput
                placeholder="Entrez un titre pour l'exercice"
                mb={10}
                radius={10}
                {...form.getInputProps(`epreuves.${index}.titre`)}
            />
            <Group position="apart">
                <Group>
                    {form.values.epreuves[index].consigne?.map((subItem, idx) => (
                        <TextInput
                            key={idx}
                            placeholder="Nombre dicté"
                            radius={10}
                            w={200}
                            {...form.getInputProps(`epreuves.${index}.consigne.${idx}.value`)}
                            onBlur={(e) => {
                                const number = parseInt(e.target.value.trim(), 10);
                                if (!isNaN(number)) {
                                    form.setFieldValue(`epreuves.${index}.correction_dp.${idx}`, number);
                                    form.setFieldValue(`epreuves.${index}.choices.${idx}`, generateAdjustedChoices(number));
                                }
                            }}
                        />
                    ))}
                </Group>
                <ActionIcon color="red" onClick={() => onRemove(index)}>
                    <X size="1rem" />
                </ActionIcon>
            </Group>
            <Divider my={20} />
        </Box>
    );
}

export default NumberDictationEpreuve; 