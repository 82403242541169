import React from 'react';
import { Box, TextInput, MultiSelect, Group, ActionIcon, Textarea, Text, Divider } from '@mantine/core';
import { X } from 'tabler-icons-react';

function CompleterEnoncesEpreuve({ form, index, onRemove }) {
    return (
        <Box key={index}>
            <TextInput
                placeholder="Mettre la consigne complète"
                mb={10}
                radius={10}
                {...form.getInputProps(`epreuves.${index}.titre`)}
            />
            <Textarea
                placeholder="Mettre la consigne complète"
                mb={10}
                radius={10}
                autosize
                minRows={3}
                {...form.getInputProps(`epreuves.${index}.consigne`)}
                value={
                    typeof form.values.epreuves[index]?.consigne === "string"
                        ? form.values.epreuves[index]?.consigne
                        : ""
                }
            />
            <Box mt={10}>
                <Text>Sélectionner les inconnues depuis la consigne</Text>
                <MultiSelect
                    radius={10}
                    {...form.getInputProps(`epreuves.${index}.correction_dp`)}
                    data={(typeof form.values.epreuves[index].consigne === "string"
                        ? form.values.epreuves[index].consigne.split(/\s+/)
                        : []
                    )
                        .filter(Boolean)
                        .map((word) => ({ value: word, label: word })) || []}
                    placeholder="Choisissez les inconnues"
                />
            </Box>
            <Group position="right">
                <ActionIcon color="red" onClick={() => onRemove(index)}>
                    <X size="1rem" />
                </ActionIcon>
            </Group>
            <Divider my={20} />
        </Box>
    );
}

export default CompleterEnoncesEpreuve; 