import React from 'react';
import { Box, TextInput, Group, ActionIcon, Divider } from '@mantine/core';
import { X, Plus } from 'tabler-icons-react';

function WordToNumbersEpreuve({ form, index, onRemove, convertWordToNumber }) {
    return (
        <Box key={index}>
            <TextInput
                placeholder="Écrire le mot à convertir en nombre"
                mb={10}
                radius={10}
                {...form.getInputProps(`epreuves.${index}.titre`)}
            />
            <Group position="apart">
                <Group>
                    {form.values.epreuves[index].consigne.map((subItem, idx) => (
                        <TextInput
                            key={idx}
                            placeholder="Mot en lettres"
                            radius={10}
                            w={200}
                            {...form.getInputProps(`epreuves.${index}.consigne.${idx}.value`)}
                            onBlur={(e) => {
                                const word = e.target.value.trim().toLowerCase();
                                const number = convertWordToNumber(word);
                                if (number !== null) {
                                    form.setFieldValue(
                                        `epreuves.${index}.correction_dp.${idx}`,
                                        number
                                    );
                                }
                            }}
                        />
                    ))}
                    <ActionIcon
                        color="green"
                        onClick={() =>
                            form.insertListItem(`epreuves.${index}.consigne`, { value: "" })
                        }
                    >
                        <Plus size="1rem" />
                    </ActionIcon>
                </Group>
                <ActionIcon color="red" onClick={() => onRemove(index)}>
                    <X size="1rem" />
                </ActionIcon>
            </Group>
            <Divider my={20} />
        </Box>
    );
}

export default WordToNumbersEpreuve; 