import React from 'react';
import { Box, TextInput, Group, ActionIcon, Textarea, Divider } from '@mantine/core';
import { X } from 'tabler-icons-react';

function ResolutionProblemeEpreuve({ form, index, onRemove }) {
    return (
        <Box key={index}>
            <TextInput
                placeholder="Mettre la consigne"
                mb={10}
                radius={10}
                {...form.getInputProps(`epreuves.${index}.titre`)}
            />
            <Textarea
                placeholder="Écrire le problème"
                mb={10}
                radius={10}
                autosize
                minRows={3}
                {...form.getInputProps(`epreuves.${index}.consigne`)}
            />
            <Group position="apart">
                <Group>
                    {form.values.epreuves[index].correction_dp.map((item, idx) => (
                        <div key={idx} style={{ display: 'flex', alignItems: 'center' }}>
                            <TextInput
                                placeholder="Résultats final"
                                radius={10}
                                {...form.getInputProps(`epreuves.${index}.correction_dp.${idx}.operation`)}
                                w={150}
                            />
                        </div>
                    ))}
                </Group>
                <ActionIcon color="red" onClick={() => onRemove(index)}>
                    <X size="1rem" />
                </ActionIcon>
            </Group>
            <Divider my={20} />
        </Box>
    );
}

export default ResolutionProblemeEpreuve; 