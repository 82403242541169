import { Box, Text, Badge } from "@mantine/core";
import EperuveTypeHeader from "./compenents/EpreuveTypeHeader";

const EpreuveResolutionProblemeEnonce = ({ epreuve }) => {
    return (
        <Box>
            <h1>Résolution de problèmes</h1>
            <Box>
                {console.log(epreuve)}
                <EperuveTypeHeader epreuve={epreuve} />
                <Box my={10}>
                    <Text>Consigne</Text>
                    <Badge
                        color="gray"
                        className="text-dark me-3"
                        variant="outline"
                    >
                        {epreuve?.consigne
                            ?.map((item) =>
                                epreuve?.correction_dp?.includes(item)
                                    ? "......" // Replace if it's in the correction
                                    : item
                            )
                            .join(" ")} {/* Join all into one badge */}
                    </Badge>
                </Box>
                <Box my={10}>
                    <Text>Choix entre</Text>
                    {epreuve?.choices?.join(" ").split(" ")
                        .map((choice, index) => (
                            <Badge
                                key={index}
                                color="gray"
                                className="text-dark me-3"
                                variant="outline"
                            >
                                {choice}
                            </Badge>
                        ))}
                </Box>
                <Box mt={15}>
                    <Text>La correction</Text>
                    {epreuve?.correction_dp?.join(" ").split(" ")
                        .map((choice, index) => (
                            <Badge
                                key={index}
                                color="gray"
                                className="text-dark me-3"
                                variant="outline"
                            >
                                {choice}
                            </Badge>
                        ))}
                </Box>
            </Box>
        </Box>
    );
}

export default EpreuveResolutionProblemeEnonce;
