/** --------------------------------------------------------------------
 * ! APIS
 * ---------------------------------------------------------------------
 */
// export const DEFAULT_API = "https://recette-genimi.fewnu.app/api/v1/";
// export const DEFAULT_API = "https://genimi-admin.bakeli.tech/api/v1/";
export const DEFAULT_API =
  process.env.REACT_APP_ENV === "production"
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_RECETTE;
// export const DEFAULT_API = "http://127.0.0.1:8000/api/v1/";
// export const DEFAULT_API = "http://192.168.68.173:8000/api/v1/";
// export const DEFAULT_IMAGE_API = "http://127.0.0.1:8000";

const generateApiPath = (basePath) => {
  return {
    GET: (id) => `${basePath}/${id}/`,
    GETALL: () => `${basePath}/`,
    GETALLv2: () => `m1/${basePath}/`,
    GETGLOBALMETRICS: () => `m1/${basePath}/metrics/global/`,
    GETMETRICS: () => `m1/${basePath}/metrics/`,
    GETCOACHSBASICINFO: () => `m1/${basePath}/basics/`,
    ADD: () => basePath + "/",
    EDIT: (id) => `${basePath}/${id}/`,
    DELETE: (id) => `${basePath}/${id}/`,
    GETPROSPECTS: () => `prospects/`,
    GETPROSPECTSSENASSURANCE: () => `prospects/?provenance=Senassurance`,
    // CONVERTPROSPECTS: (id) => `prospects/convertir/${id}/`,
    CONVERTPROSPECTS: (id) => `prospects/${id}/`,
  };
};

const API = {
  USER_API: {
    LOGIN: "login/",
    REGISTER: "users/",
    GET_USER: "",
    LOGOUT: "",
  },

  KPI: {
    GETALL: () => "kpi/",
  },

  WAVE: {
    GET: () => "wave/solde/",
  },

  COACHS_API: {
    ...generateApiPath("coachs"),
  },

  DIRECTEUR: {
    ...generateApiPath("directeurs"),
  },

  FICHES: {
    ...generateApiPath("fiches"),
    ASSIGNATION_FICHE_COACH: () => `fiches/assignations/`,
    GETALLCOACHSFICHE: (ficheId) => `fiches/coachs/?coach=${ficheId}`,
    // DESACFECTERCOACHFICHE: (ficheId, coachId) =>
    //   `fiches/${ficheId}/coachs/${coachId}`,
    DESACFECTERCOACHFICHE: (ficheId, coachId) =>
      `fiches/assignations/?ficheId=${ficheId}&coachId=${coachId}`,
  },

  COMPOSITION: {
    ...generateApiPath("compositions"),
    GET_ALL_BY_CLASSE: (id) => `compositions/classes/${id}/`,
    GET_ALL_BY_ELEVE: (id) => `m1/students/${id}/compositions/`,
  },

  MAITRE: {
    ...generateApiPath("maitres"),
  },

  ELEVES_API: {
    ...generateApiPath("eleves"),
  },

  APPELS_API: {
    ...generateApiPath("appels"),
  },
  PARENTS_API: {
    ...generateApiPath("parents"),
  },
  PARTENAIRES_API: {
    ...generateApiPath("partenaire"),
  },
  PARRAINS_API: {
    ...generateApiPath("parrains"),
  },
  MATIERES_API: {
    ...generateApiPath("matieres"),
  },

  ECOLES_API: {
    ...generateApiPath("schools"),
  },

  CLASSES_API: {
    ...generateApiPath("classes"),
    ADD_HISTORIQUE: () => "classes/historiques/",
  },

  VILLES_API: {
    ...generateApiPath("villes"),
  },

  FICHES_API: {
    ...generateApiPath("fiches"),
    ASSIGN_TO_COACH: () => `fiches/assignations/`,
  },

  NIVEAUX_API: {
    ...generateApiPath("niveaux"),
  },

  DIAGNOSTIUES_API: {
    ...generateApiPath("diagnostics"),
    GET_WITH_NIVEAUX: (id) => "diagnostics/niveaux/" + id + "/",
    GET_DETAILS_NIVEAU: (diagnosticId, niveauId) =>
      "m1/epreuves/?diagnosticId=" + diagnosticId + "&niveauId=" + niveauId,
  },

  DIAGNOSTICS_NIVEAU_API: {
    ...generateApiPath("diagnostics/niveaux"),
  },

  DIAGNOSTICS_PARENT: {
    ...generateApiPath("diagnostics/parents"),
    // ...generateApiPath("diagnostics/epreuves/parents"),
  },

  EPREUVES_API: {
    ...generateApiPath("epreuves"),
  },

  PAYEMENTS_API: {
    ...generateApiPath("payements"),
    GETALLBYPARENT: () => "payments/parents/",
  },

  NOTES_API: {
    ...generateApiPath("notes"),
    GETALL_GROUP_BY_CLASSE: () => "notes/classes/",
    GETALL_DIAGNOSTIC_NOTES_STUDENT: (id) => `notes/?tentative=${id}`,
    GETALL_DIAGNOSTIC_STUDENT_LIST: (id) => "notes/diagnostics/" + id + "/",
    GETALL_DIAGNOSTIC_STUDENT_LIST_BY_STUDENT: (id, studentId) =>
      "notes/diagnostics/" + id + "/student/" + studentId + "/",
  },
};

export default API;
