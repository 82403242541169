import React from 'react';
import { Box, TextInput, Group, ActionIcon, Divider } from '@mantine/core';
import { X, Plus } from 'tabler-icons-react';

function OrderEpreuve({ form, index, onRemove, type }) {
    const title = type === 'oc'
        ? "Mettre dans l'ordre croissant les nombres"
        : "Mettre dans l'ordre decroissant les nombres";

    return (
        <Box key={index}>
            <TextInput
                placeholder={title}
                mb={10}
                radius={10}
                {...form.getInputProps(`epreuves.${index}.titre`)}
            />
            <Group position="apart">
                <Group>
                    {form.values.epreuves[index].consigne.map((item, idx) => (
                        <TextInput
                            key={idx}
                            type="number"
                            {...form.getInputProps(`epreuves.${index}.consigne.${idx}`)}
                            radius={10}
                            w={80}
                            placeholder="Nombre"
                        />
                    ))}
                    <ActionIcon
                        color="green"
                        onClick={() =>
                            form.insertListItem(`epreuves.${index}.consigne`, "")
                        }
                    >
                        <Plus size="1rem" />
                    </ActionIcon>
                </Group>
                <ActionIcon color="red" onClick={() => onRemove(index)}>
                    <X size="1rem" />
                </ActionIcon>
            </Group>
            <Divider my={20} />
        </Box>
    );
}

export default OrderEpreuve; 