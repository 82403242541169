import React from 'react';
import { Box, TextInput, Group, ActionIcon, Divider } from '@mantine/core';
import { X } from 'tabler-icons-react';

function MathEpreuve({ form, index, onRemove }) {
    return (
        <Box key={index}>
            <TextInput
                placeholder="Mettre la consigne"
                mb={10}
                radius={10}
                {...form.getInputProps(`epreuves.${index}.titre`)}
            />
            <Group position="apart">
                <Group>
                    {form.values.epreuves[index].consigne.map((item, idx) => (
                        <TextInput
                            key={idx}
                            placeholder="Mettre l'operation"
                            radius={10}
                            {...form.getInputProps(`epreuves.${index}.consigne.${idx}.value`)}
                            w={150}
                        />
                    ))}
                </Group>
                <Group>
                    <ActionIcon color="red" onClick={() => onRemove(index)}>
                        <X size="1rem" />
                    </ActionIcon>
                </Group>
            </Group>
            <Divider my={20} />
        </Box>
    );
}

export default MathEpreuve; 