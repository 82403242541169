import n2words from 'n2words';

export function generateAdjustedChoices(correctNumber) {
    const alternatives = new Set();
    alternatives.add(correctNumber.toString());

    while (alternatives.size < 4) {
        const randomVariant =
            correctNumber +
            Math.floor(Math.random() * 10) * (Math.random() > 0.5 ? 1 : -1);
        alternatives.add(randomVariant.toString());
    }

    return Array.from(alternatives);
}

export function convertWordToNumber(word) {
    const frenchNumbers = Array.from({ length: 100 }, (_, i) => ({
        word: n2words(i, { lang: "fr" }),
        number: i,
    }));

    const match = frenchNumbers.find((item) => item.word === word);
    return match ? match.number : null;
} 