import { Box, Text, Badge } from "@mantine/core";
import EperuveTypeHeader from "./compenents/EpreuveTypeHeader";

const EpreuveResolutionProblemOperation = ({ epreuve }) => {
    return (
        <Box>
            <h1>Résolution de problèmes</h1>
            <Box>
                {
                    console.log(epreuve)
                }
                <EperuveTypeHeader epreuve={epreuve} />
                <Box my={10}>
                    <Text>Consigne</Text>
                    <Badge
                        color="gray"
                        className="text-dark me-3 text-lg"
                        variant="outline"
                    >
                        {epreuve?.consigne[0]}
                    </Badge>
                </Box>

                <Box mt={15}>
                    <Text>La correction</Text>
                    {
                        epreuve?.correction_dp?.map((choice) =>
                            <Badge
                                color="gray"
                                className="text-dark me-3"
                                variant="outline"
                            >
                                {choice}
                            </Badge>)
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default EpreuveResolutionProblemOperation;