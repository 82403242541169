import React from 'react';
import { Box, TextInput, Group, ActionIcon, Divider } from '@mantine/core';
import { X, Plus } from 'tabler-icons-react';
import { generateAdjustedChoices } from '../../utils/epreuveUtils';

function NumberToWordsEpreuve({ form, index, onRemove }) {
    return (
        <Box key={index}>
            <TextInput
                placeholder="Écrire les nombres en lettres"
                mb={10}
                radius={10}
                {...form.getInputProps(`epreuves.${index}.titre`)}
            />
            <Group position="apart">
                <Group>
                    {form.values.epreuves[index].consigne.map((subItem, idx) => {
                        // Ensure subItem is always an object
                        const parsedItem =
                            typeof subItem === 'string' ? JSON.parse(subItem) : subItem;

                        return (
                            <TextInput
                                key={idx}
                                type="number"
                                placeholder="Nombre"
                                radius={10}
                                w={100}
                                value={parsedItem.value || ""} // Ensure value is set
                                onChange={(e) => {
                                    const newValue = e.target.value.trim();
                                    const updatedItem = { ...parsedItem, value: newValue };

                                    form.setFieldValue(
                                        `epreuves.${index}.consigne.${idx}`,
                                        JSON.stringify(updatedItem) // Store as string to match format
                                    );
                                }}
                                onBlur={(e) => {
                                    const number = parseInt(e.target.value.trim(), 10);
                                    if (!isNaN(number)) {
                                        form.setFieldValue(
                                            `epreuves.${index}.correction_dp.${idx}`,
                                            number
                                        );
                                        form.setFieldValue(
                                            `epreuves.${index}.choices`,
                                            generateAdjustedChoices(number)
                                        );
                                    }
                                }}
                            />
                        );
                    })}
                    <ActionIcon
                        color="green"
                        onClick={() =>
                            form.insertListItem(`epreuves.${index}.consigne`, { value: "" })
                        }
                    >
                        <Plus size="1rem" />
                    </ActionIcon>
                </Group>
                <ActionIcon color="red" onClick={() => onRemove(index)}>
                    <X size="1rem" />
                </ActionIcon>
            </Group>
            <Divider my={20} />
        </Box>
    );
}

export default NumberToWordsEpreuve; 