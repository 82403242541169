import {
  ActionIcon,
  Alert,
  Anchor,
  Box,
  Button,
  Divider,
  Group,
  MultiSelect,
  Notification,
  NumberInput,
  Paper,
  Popover,
  Select,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useState, useEffect } from "react";
import { AlertCircle, Check, Plus, X } from "tabler-icons-react";
import { useAddDiagnosticParentsMutation, useEditDiagnosticParentsMutation } from "../../../redux/features/diagnosticParentApi";
import AddAction from "./actions/AddAction";
import { useGetDiagnostiquesQuery } from "../api/features/diagnostiqueApi";
import { useGetNiveauxQuery } from "../../../redux/features/niveauApi";
import AlphabetForm from "./components/AlphabetForm";
import OrganisationForm from "./components/OrganisationForm";
import AudioRecordingForm from "./components/AudioRecordingForm";
import TextSpeachForm from "./components/TextSpeachForm";
import LettreManquanteForm from "./components/LettreManquanteForm";
import AudioRecordingWithTimerForm from "./components/AudioRecordingWithTimerForm";
import FigureForm from "./components/FigureForm";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import TSQ from "./components/TSQ";
import { nanoid } from "@reduxjs/toolkit";
import n2words from "n2words";
import { useGetEpreuveQuery } from "../../../redux/features/epreuveApi";
import {
  MathEpreuve,
  MissingNumbersEpreuve,
  NumberToWordsEpreuve,
  WordToNumbersEpreuve,
  NumberDictationEpreuve,
  CompleterEnoncesEpreuve,
  ResolutionProblemeEpreuve,
  OrderEpreuve
} from './components/epreuve-types';
import { generateAdjustedChoices, convertWordToNumber } from './utils/epreuveUtils';

function ParentDiagnosticPage() {
  const { id } = useParams();
  const { state } = useLocation();
  const isEditing = Boolean(id);

  console.log('ParentDiagnosticPage Debug:', {
    id,
    state,
    isEditing,
    currentPath: window.location.pathname,
    currentSearch: window.location.search
  });

  // Use state.epreuve if available, otherwise fetch
  const { data: existingEpreuve, isLoading: isLoadingEpreuve } = useGetEpreuveQuery(id, {
    skip: !isEditing || Boolean(state?.epreuve),
  });

  // Use state.epreuve or fetched data
  const epreuveData = state?.epreuve || existingEpreuve?.data;

  const navigation = useNavigate();

  const [closed, setClosed] = useState(false);

  const { data: diagnosticData } = useGetDiagnostiquesQuery();
  const { data: niveaux } = useGetNiveauxQuery();

  const [add, { isLoading, isSuccess, isError }] = useAddDiagnosticParentsMutation();
  const [edit, { isLoading: isUpdating, isSuccess: isUpdateSuccess, isError: isUpdateError }] = useEditDiagnosticParentsMutation();

  const form = useForm({
    initialValues: {
      niveauId: "",
      diagnosticId: "",
      epreuves: [],
      bareme: "",
    },
  });

  const [errors, setErrors] = useState([]);

  // Update form when data is available
  useEffect(() => {
    if (isEditing && epreuveData) {
      let formattedConsigne;
      let formattedCorrection = [];

      if (epreuveData.type === 'missingNumbers') {
        // Parse consigne items if they're strings
        formattedConsigne = epreuveData.consigne.map(item =>
          typeof item === 'string' ? item : JSON.stringify(item)
        );
        formattedCorrection = Array.isArray(epreuveData.correction_dp)
          ? epreuveData.correction_dp.map(String)
          : [];
      }

      if (epreuveData.type === 'numberToWords') {
        formattedConsigne = epreuveData.consigne.map(item =>
          typeof item === 'string' ? JSON.parse(item) : item
        );
        formattedCorrection = Array.isArray(epreuveData.correction_dp)
          ? epreuveData.correction_dp.map(String)
          : [];
      }

      const formattedEpreuve = {
        niveauId: epreuveData.niveauId?.id || epreuveData.niveauId,
        diagnosticId: epreuveData.diagnosticId?.id || epreuveData.diagnosticId,
        bareme: epreuveData.bareme,
        epreuves: [{
          id: epreuveData.id,
          type: epreuveData.type,
          titre: epreuveData.titre,
          consigne: epreuveData.type === 'missingNumbers'
            ? formattedConsigne
            : epreuveData.consigne,
          correction_dp: epreuveData.type === 'missingNumbers'
            ? formattedCorrection
            : epreuveData.correction_dp,
        }],
      };

      console.log('Formatted Epreuve:', formattedEpreuve); // Debug log
      form.setValues(formattedEpreuve);
    }
  }, [epreuveData, isEditing]);

  const renderEpreuve = (epreuve, index) => {
    const commonProps = {
      form,
      index,
      onRemove: () => form.removeListItem('epreuves', index)
    };

    switch (epreuve.type) {
      case 'operation':
        return <MathEpreuve {...commonProps} />;
      case 'missingNumbers':
        return <MissingNumbersEpreuve {...commonProps} />;
      case 'numberToWords':
        return <NumberToWordsEpreuve {...commonProps} generateAdjustedChoices={generateAdjustedChoices} />;
      case 'wordToNumbers':
        return <WordToNumbersEpreuve {...commonProps} convertWordToNumber={convertWordToNumber} />;
      case 'numberDictation':
        return <NumberDictationEpreuve {...commonProps} generateAdjustedChoices={generateAdjustedChoices} />;
      case 'completerEnonces':
        return <CompleterEnoncesEpreuve {...commonProps} />;
      case 'resolutionProbleme':
        return <ResolutionProblemeEpreuve {...commonProps} />;
      case 'oc':
      case 'od':
        return <OrderEpreuve {...commonProps} type={epreuve.type} />;
      default:
        return null;
    }
  };

  const handleSubmitMath = form.onSubmit(async (values) => {
    if (!values?.bareme) {
      return alert("Il faudra mettre le bareme");
    }

    let isError = false;

    const reformdata = values.epreuves.map((item) => {
      if (item.type === "operation") {
        let operations = [];
        item.consigne.map((item) => operations.push(item.value));
        return {
          ...item,
          consigne: item.consigne?.map((item) => {
            const valide = eval(operations.join("").split("=")[0]);
            if (
              valide ||
              valide === parseInt(operations.join("").split("=")[1])
            ) {
              return { ...item };
            } else {
              setErrors([...errors, operations.join("")]);
              return (isError = true);
            }
          }),
        };
      }
      else if (item.type === "missingNumbers") {
        if (!item.consigne || !Array.isArray(item.consigne)) {
          throw new Error("Données invalides : la consigne doit être un tableau.");
        }

        const startIndex = item.consigne.findIndex(val => val.value !== "");
        const endIndex = item.consigne.length - 1 - item.consigne.slice().reverse().findIndex(val => val.value !== "");

        if (startIndex === -1 || endIndex === -1) {
          return alert("Les bornes de début ou de fin sont absentes.");
        }

        const startValue = Number(item.consigne[startIndex].value);
        const endValue = Number(item.consigne[endIndex].value);

        if (startValue === 0 || endValue === 0) {
          return alert("Les bornes doivent être vides ou égales a 0");
        }

        if (isNaN(startValue) || isNaN(endValue)) {
          return alert("Les bornes doivent être des nombres valides.");
        }

        if (startValue >= endValue) {
          return alert("La borne de fin doit être supérieure à la borne de début.");
        }

        if ((endValue - startValue) % 5 !== 0) {
          return alert("Les bornes ne permettent pas de compter par 5 correctement.");
        }

        // Generate all numbers in the sequence for correction_dp
        const allNumbers = [];
        for (let i = startValue; i <= endValue; i += 5) {
          allNumbers.push(i.toString());
        }

        return {
          ...item,
          consigne: item.consigne.map(c => JSON.stringify(c)),
          correction_dp: allNumbers // Set correction_dp to all numbers in sequence
        };
      }
      else if (item.type === "numberToWords") {
        const reformattedConsigne = item.consigne.map((subItem, index) => {
          const expectedWord = n2words(parseInt(subItem.value, 10), { lang: "fr" });
          return subItem;
        });

        return {
          ...item,
          consigne: reformattedConsigne,
          correction_dp: reformattedConsigne.map((subItem) =>
            n2words(parseInt(subItem.value, 10), { lang: "fr" })
          ),
        };
      }
      else if (item.type === "wordToNumbers") {
        const reformattedConsigne = item.consigne.map((subItem, index) => {
          const word = subItem.value.trim().toLowerCase();
          const expectedNumber = convertWordToNumber(word);
          return subItem;
        });

        return {
          ...item,
          consigne: reformattedConsigne,
          correction_dp: reformattedConsigne.map((subItem) =>
            convertWordToNumber(subItem.value.trim().toLowerCase())
          ),
        };
      }
      else if (item.type === "numberDictation") {
        const consigneValue = item.consigne?.[0]?.value.trim();
        const correctNumber = parseInt(consigneValue, 10);

        if (isNaN(correctNumber)) {
          setErrors((prevErrors) => [
            ...prevErrors,
            `Veuillez entrer un nombre valide.`,
          ]);
          isError = true;
        }

        return {
          ...item,
          consigne: [correctNumber.toString()],
          correction_dp: [correctNumber.toString()],
          choices: [correctNumber.toString()],
        };
      }
      else if (item.type === "completerEnonces") {
        const words = item.consigne.split(/\s+/);
        const inconnus = [];
        const correction = [];

        if (Array.isArray(item.correction_dp)) {
          item.correction_dp.forEach((missingWord) => {
            if (typeof missingWord === "string") {
              inconnus.push(missingWord);
              correction.push(missingWord);
            } else {
              console.warn("Invalid value in correction_dp: ", missingWord);
            }
          });
        } else {
          console.error("correction_dp is not an array: ", item.correction_dp);
          isError = true;
          setErrors([...errors, "Correction data is invalid."]);
          return { ...item, consigne: words };
        }

        if (inconnus.length === 0) {
          isError = true;
          setErrors([...errors, "Aucun mot marqué comme inconnu."]);
          return { ...item, consigne: words };
        }

        return {
          ...item,
          consigne: words,
          correction_dp: correction,
          inconnus,
        };
      }
      else if (item.type === "resolutionProbleme") {
        const consigneArray = Array.isArray(item.consigne)
          ? item.consigne.map((consigneItem) => consigneItem.toString())
          : [item.consigne.toString()];

        const correctionArray = item.correction_dp.map((correctionItem) => {
          const operation = correctionItem.operation || "";
          const sign = correctionItem.sign || "";
          const result = correctionItem.result || "";
          return `${operation} ${sign} ${result}`;
        });

        return {
          ...item,
          consigne: consigneArray,
          correction_dp: correctionArray,
        };
      }
      else if (item.type === "oc") {
        return {
          ...item,
          correction_dp: item?.consigne.toSorted((a, b) => a - b),
        };
      } else if (item.type === "od") {
        return {
          ...item,
          correction_dp: item?.consigne.toSorted((a, b) => b - a),
        };
      } else if (item.type === "figure") {
        return {
          ...item,
          correction_dp: item?.correction_dp,
        };
      } else if (item.type === "win" || item.type === "wil") {
        return {
          ...item,
          correction_dp: item?.consigne,
        };
      } else {
        return { ...item };
      }
    });

    if (isError) {
      return;
    }

    try {
      const newData = {
        ...values,
        epreuves: reformdata.map((item) =>
          item.type === "operation" || item.type === "missingNumbers" || item.type === "numberToWords" || item.type === "wordToNumbers"
            ? {
              ...item,
              consigne: item?.consigne.map((item) =>
                JSON.stringify({ type: item?.type, value: item?.value })
              ),
            }
            : item
        ),
      };

      if (isEditing) {
        const editData = {
          diagnosticId: newData.diagnosticId,
          niveauId: newData.niveauId,
          bareme: newData.bareme,
          ...newData.epreuves[0],
          type_diagnostic: 'PARENT'
        };

        await edit({
          body: editData
        });

      } else {
        await AddAction(add, newData);
      }

      navigation(-1);
      setClosed(true);
      form.reset();
    } catch (error) {
      console.log(error);
      return;
    }
  });

  const handleSubmitLecture = form.onSubmit(async (values) => {
    if (!values?.bareme) {
      return alert("Il faudra mettre le bareme");
    }

    try {
      const formattedData = {
        ...values,
        epreuves: values?.epreuves.map((item) => ({
          ...item,
          choices:
            typeof item?.choices !== "number"
              ? [...item?.choices]
              : [item?.choices?.toString()],
          correction_dp:
            item?.type === "alphabet" || item?.type === "lettreManquante"
              ? item?.correction_dp.split("-")
              : item?.type === "figure"
                ? item?.correction_dp
                : item?.type === "tsq"
                  ? [JSON.stringify(item?.correction_dp)]
                  : item?.type === "dicteeChronometree"
                    ? [item?.correction_dp]
                    : item?.correction_dp.split(" "),
        })),
      };

      if (isEditing) {
        const editData = {
          diagnosticId: formattedData.diagnosticId,
          niveauId: formattedData.niveauId,
          bareme: formattedData.bareme,
          ...formattedData.epreuves[0],
          type_diagnostic: 'PARENT'
        };

        await edit({
          body: editData
        });
      } else {
        await AddAction(add, formattedData);
      }

      navigation(-1);
      setClosed(true);
      form.reset();
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <Box pb={30}>
      {closed && (isSuccess || isUpdateSuccess) ? (
        <Notification
          mb={20}
          color="green"
          title={isEditing ? "Modification d'epreuves" : "Ajout d'epreuves"}
          icon={<Check size="1.2rem" />}
          onClose={() => setClosed(false)}
        >
          {isEditing ? "Modification des epreuves reussie" : "Ajout des epreuves reussi"}
        </Notification>
      ) : closed && (isError || isUpdateError) ? (
        <Notification
          onClose={() => setClosed(false)}
          mb={20}
          color="red"
          title={isEditing ? "Modification d'epreuves" : "Ajout d'epreuves"}
          icon={<X size="1.2rem" />}
        >
          Une erreur c'est produite lors de {isEditing ? "la modification" : "l'ajout"} des epreuves
        </Notification>
      ) : null}

      {isLoadingEpreuve ? (
        <Alert icon={<AlertCircle size="1rem" />} color="blue" radius="lg">
          Chargement des données...
        </Alert>
      ) : (
        <form
          onSubmit={(values) =>
            niveaux?.data.find((item) => item?.id === form?.values?.niveauId)
              ?.matiere?.name === "MATHEMATIQUE"
              ? handleSubmitMath(values)
              : handleSubmitLecture(values)
          }
        >
          <Group>
            <Select
              placeholder="Selectionner"
              label="Diagnostique"
              data={diagnosticData?.data.map((item) => ({
                value: item?.id,
                label: item?.title + ` (${item?.type_diagnostique})`,
              })) ?? []}
              withAsterisk
              radius={10}
              mb={20}
              disabled={isLoadingEpreuve}
              {...form.getInputProps("diagnosticId")}
              w={300}
            />

            <Select
              placeholder="Selectionner"
              label="Niveaux"
              data={
                niveaux?.data
                  ?.filter((niveau) => {
                    const selectedDiagnostic = diagnosticData?.data.find(
                      (diagnostic) => diagnostic.id === form.values.diagnosticId
                    );
                    return selectedDiagnostic
                      ? niveau.matiereId === selectedDiagnostic.matiereId
                      : true;
                  })
                  .map((niveau) => ({
                    value: niveau.id,
                    label: niveau.name,
                  })) ?? []
              }
              withAsterisk
              radius={10}
              mb={20}
              disabled={isLoadingEpreuve || !form.values.diagnosticId}
              {...form.getInputProps("niveauId")}
            />

            <NumberInput
              withAsterisk
              radius={10}
              mb={20}
              placeholder="0"
              label={"Bareme"}
              disabled={isLoadingEpreuve}
              {...form.getInputProps("bareme")}
            />
          </Group>

          {errors.length > 0 ? (
            <Alert
              icon={<AlertCircle size="1.1rem" />}
              title="Operation Invalide"
              color="red"
              radius="lg"
              withCloseButton
              mt={20}
              onClose={() => setErrors([])}
            >
              {errors.map((error) => (
                <Text>{error}</Text>
              ))}
            </Alert>
          ) : null}

          {form.values.diagnosticId && form.values.niveauId ? (
            <>
              {niveaux?.data.find((item) => item?.id === form?.values?.niveauId)
                ?.matiere?.name === "MATHEMATIQUE" ? (
                <>
                  <Paper p={10} withBorder radius={10} my={20}>
                    <Box>
                      {form.values.epreuves.map((epreuve, index) =>
                        renderEpreuve(epreuve, index)
                      )}

                      <Popover position="bottom" withArrow shadow="md">
                        <Popover.Target>
                          <Button radius={10}>Ajouter une epreuve</Button>
                        </Popover.Target>
                        <Popover.Dropdown>
                          <Anchor
                            size="sm"
                            onClick={() =>
                              form.insertListItem("epreuves", {
                                id: form.values.epreuves.length + 1,
                                correction_dp: [],
                                type: "operation",
                                titre: "Trouver l'inconnue",
                                choices: [""],
                                consigne: [
                                  { id: 1, type: "n", value: "" },
                                  { id: 2, type: "s", value: "" },
                                  { id: 3, type: "n", value: "" },
                                  { id: 4, type: "s", value: "=" },
                                  { id: 5, type: "n", value: "" },
                                ],
                              })
                            }
                          >
                            Operation
                          </Anchor>

                          <Divider my={5} />
                          <Anchor
                            size="sm"
                            onClick={() =>
                              form.insertListItem("epreuves", {
                                id: form.values.epreuves.length + 1,
                                correction_dp: [],
                                type: "missingNumbers",
                                titre: "Complétez la liste en comptant par 5",
                                consigne: [{ value: "", type: "number" }],
                              })
                            }
                          >
                            Compléte la liste en comptant par 5
                          </Anchor>
                          <Divider my={5} />
                          <Anchor
                            size="sm"
                            onClick={() =>
                              form.insertListItem("epreuves", {
                                id: form.values.epreuves.length + 1,
                                correction_dp: [],
                                type: "numberToWords",
                                titre: "Écrire les nombres en lettres",
                                consigne: [{ value: "", type: "text" }],
                              })
                            }
                          >
                            Écrire les nombres en lettres
                          </Anchor>
                          <Divider my={5} />
                          <Anchor
                            size="sm"
                            onClick={() =>
                              form.insertListItem("epreuves", {
                                id: form.values.epreuves.length + 1,
                                correction_dp: [],
                                type: "wordToNumbers",
                                titre: "Écrire les nombres en chiffres",
                                consigne: [{ value: "", type: "text" }],
                              })
                            }
                          >
                            Écrire les nombres en chiffres
                          </Anchor>
                          <Divider my={5} />
                          <Anchor
                            size="sm"
                            onClick={() =>
                              form.insertListItem("epreuves", {
                                id: form.values.epreuves.length + 1,
                                correction_dp: [],
                                type: "numberDictation",
                                titre: "Dictée des nombres",
                                consigne: [{ value: "" }],
                                choices: [],
                              })
                            }
                          >
                            Dictée des nombres
                          </Anchor>
                          <Divider my={5} />
                          <Anchor
                            size="sm"
                            onClick={() =>
                              form.insertListItem("epreuves", {
                                id: form.values.epreuves.length + 1,
                                correction_dp: [
                                  {
                                    titre: "",
                                    id: nanoid(),
                                    responses: [
                                      { titre: "", isCorrect: false, id: nanoid() },
                                    ],
                                  },
                                ],
                                type: "completerEnonces",
                                titre: "Résolution de problèmes: Compléter les énoncés",
                                consigne: [
                                  {
                                    value:
                                      "",
                                    type: "text",
                                  },
                                ],
                              })
                            }
                          >
                            Résolution de problèmes: Compléter les énoncés
                          </Anchor>
                          <Divider my={5} />
                          <Anchor
                            size="sm"
                            onClick={() =>
                              form.insertListItem("epreuves", {
                                id: form.values.epreuves.length + 1,
                                correction_dp: [
                                  {
                                    titre: "",
                                    id: nanoid(),
                                    responses: [
                                      { titre: "", isCorrect: false, id: nanoid() },
                                    ],
                                  },
                                ],
                                type: "resolutionProbleme",
                                titre: "Résolution de problèmes: Opération",
                                consigne: [
                                  {
                                    value:
                                      "",
                                    type: "text",
                                  },
                                ],
                              })
                            }
                          >
                            Résolution de problèmes: Opération
                          </Anchor>
                          <Divider my={5} />
                          <Anchor
                            size="sm"
                            onClick={() =>
                              form.insertListItem("epreuves", {
                                id: form.values.epreuves.length + 1,
                                correction_dp: "",
                                type: "figure",
                                titre: "Choisie le cercle",
                                choices: [],
                                consigne: [""],
                              })
                            }
                          >
                            Figure
                          </Anchor>
                          <Divider my={5} />
                          <Anchor
                            size="sm"
                            onClick={() =>
                              form.insertListItem("epreuves", {
                                id: form.values.epreuves.length + 1,
                                correction_dp: [],
                                type: "oc",
                                titre:
                                  "Mettre dans l'ordre croissant les nombres",
                                consigne: [""],
                                choices: [""],
                              })
                            }
                          >
                            Ordre croissant
                          </Anchor>
                          <Divider my={5} />
                          <Anchor
                            size="sm"
                            onClick={() =>
                              form.insertListItem("epreuves", {
                                id: form.values.epreuves.length + 1,
                                correction_dp: [],
                                type: "od",
                                titre:
                                  "Mettre dans l'ordre decroissant les nombres",
                                consigne: [""],
                                choices: [""],
                              })
                            }
                          >
                            Ordre decroissant
                          </Anchor>

                        </Popover.Dropdown>
                      </Popover>
                    </Box>
                  </Paper>
                </>
              ) : (
                <Box radius={10} my={20}>
                  {/* ... lecture diagnostics ... */}
                  <Popover position="bottom" withArrow shadow="md">
                    <Popover.Target>
                      <Button radius={10}>Ajouter une epreuve</Button>
                    </Popover.Target>
                    <Popover.Dropdown>
                      <Anchor
                        size="sm"
                        onClick={() =>
                          form.insertListItem("epreuves", {
                            id: nanoid(),
                            correction_dp: "",
                            type: "organisation",
                            titre: "Reorganiser la phrase",
                            choices: [],
                            consigne: [""],
                          })
                        }
                      >
                        Organisation des mots
                      </Anchor>
                      <Divider my={5} />
                      <Anchor
                        size="sm"
                        onClick={() =>
                          form.insertListItem("epreuves", {
                            id: nanoid(),
                            correction_dp: [
                              {
                                titre: "",
                                id: nanoid(),
                                responses: [
                                  { titre: "", isCorrect: false, id: nanoid() },
                                ],
                              },
                            ],
                            type: "tsq",
                            titre: "Lis le texte puis réponds aux questions.",
                            choices: [],
                            consigne: [""],
                          })
                        }
                      >
                        Text suivis de Questions
                      </Anchor>
                      <Divider my={5} />
                      <Anchor
                        size="sm"
                        onClick={() =>
                          form.insertListItem("epreuves", {
                            id: nanoid(),
                            correction_dp: "",
                            type: "figure",
                            titre: "Choisie le cercle",
                            choices: [],
                            consigne: [""],
                          })
                        }
                      >
                        Figure
                      </Anchor>
                      <Divider my={5} />
                      <Anchor
                        size="sm"
                        onClick={() =>
                          form.insertListItem("epreuves", {
                            id: nanoid(),
                            correction_dp:
                              "A-B-C-D-E-F-G-H-I-J-K-L-M-N-O-P-Q-R-S-T-U-V-W-X-Y-Z",
                            type: "alphabet",
                            titre: "Lister les lettres de l'alphabet.",
                            choices: [],
                            consigne: [],
                          })
                        }
                      >
                        Alphabet
                      </Anchor>
                      <Divider my={5} />
                      <Anchor
                        size="sm"
                        onClick={() =>
                          form.insertListItem("epreuves", {
                            id: nanoid(),
                            correction_dp:
                              "A-B-C-D-E-F-G-H-I-J-K-L-M-N-O-P-Q-R-S-T-U-V-W-X-Y-Z",
                            type: "lettreManquante",
                            titre:
                              "Remplir les lettres manquantes de l'alphabet.",
                            choices: [],
                            consigne: [],
                          })
                        }
                      >
                        Lettre manquante
                      </Anchor>
                      <Divider my={5} />
                      <Anchor
                        size="sm"
                        onClick={() =>
                          form.insertListItem("epreuves", {
                            id: nanoid(),
                            correction_dp: "",
                            type: "dictee",
                            titre: "Écrivez les sons que vous entendez.",
                            choices: [],
                            consigne: [],
                          })
                        }
                      >
                        dictee
                      </Anchor>
                      <Divider my={5} />
                      <Anchor
                        size="sm"
                        onClick={() =>
                          form.insertListItem("epreuves", {
                            id: nanoid(),
                            correction_dp: "",
                            type: "dicteeChronometree",
                            titre: "Liser le text que vous voyez en 1 minute.",
                            choices: 1,
                            consigne: [],
                          })
                        }
                      >
                        dictee chronometree
                      </Anchor>
                      <Divider my={5} />
                      <Anchor
                        size="sm"
                        onClick={() =>
                          form.insertListItem("epreuves", {
                            id: nanoid(),
                            correction_dp: "",
                            type: "transcription",
                            titre: "Liser le mot que vous voyez.",
                            choices: [],
                            consigne: [],
                          })
                        }
                      >
                        transcription
                      </Anchor>
                    </Popover.Dropdown>
                  </Popover>
                </Box>
              )}
              <Group position="right">
                <Button
                  radius={10}
                  color="gray"
                  onClick={() => navigation(-1)}
                >
                  Retour
                </Button>
                <Button
                  radius={10}
                  color="gray"
                  onClick={() => form.reset()}
                >
                  Annuler
                </Button>
                <Button
                  radius={10}
                  type="submit"
                  loading={isLoading || isUpdating}
                >
                  {isEditing ? 'Modifier' : 'Enregistrer'} les epreuves
                </Button>
              </Group>
            </>
          ) : (
            <Alert icon={<AlertCircle size="1rem" />} color="orange" radius="lg">
              Veillez selection la matiere et la classe
            </Alert>
          )}
        </form>
      )}
    </Box>
  );
}

export default ParentDiagnosticPage;
