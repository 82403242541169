import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetEpreuveQuery } from "../../../redux/features/epreuveApi";
import { ActionIcon, Box, Button, Center, Group, Loader } from "@mantine/core";
import { ArrowLeft } from "tabler-icons-react";
import EpreuveOrganisation from "./types/EpreuveOrganisation";
import EperuveNullType from "./types/NullType";
import EpreuveAlphabet from "./types/EpreuveAlphabet";
import EpreuveDictee from "./types/EpreuveDicte";
import EpreuveNombreManquant from "./types/EpreuveNombreManquant";
import EpreuveTypeOCandOD from "./types/EpreuveTypeOC&OD";
import EpreuveFigure from "./types/EpreuveFigure";
import EpreuveTypeTSQ from "./types/EpreuveTypeTSQ";
import EpreuveTypeOperation from "./types/EpreuveTypeOperation";
import EpreuveTypeNumberDictation from "./types/EpreuveTypeNumberDictation";
import EpreuveResolutionProblemOperation from "./types/EpreuveResolutionProblemOperation";
import EpreuveResolutionProblemeEnonce from "./types/EpreuveResolutionProblemeEnonce";

const EpreuveDetailPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { state } = useLocation();
    const epreuveId = state?.id || params?.id;
    const { data: epreuve, isLoading } = useGetEpreuveQuery(epreuveId);
    const type = epreuve?.data?.type;

    console.log('EpreuveDetails Debug:', {
        params,
        state,
        epreuveId,
        epreuveData: epreuve?.data
    });

    console.log("type", type);

    // Fonction pour rendre le bon composant en fonction du type
    const renderEpreuve = () => {
        switch (type) {
            case "missingNumbers":
            case "numberToWords":
            case "wordToNumbers":
                return <EpreuveNombreManquant epreuve={epreuve?.data} />;
            case "operation":
                return <EpreuveTypeOperation epreuve={epreuve?.data} />;
            case "numberDictation":
                return <EpreuveTypeNumberDictation epreuve={epreuve?.data} />;
            case "organisation":
            case "lettreManquante":
                return <EpreuveOrganisation epreuve={epreuve?.data} />;
            case "figure":
                return <EpreuveFigure epreuve={epreuve?.data} />;
            case "alphabet":
                return <EpreuveAlphabet epreuve={epreuve?.data} />;
            case "od":
            case "oc":
                return <EpreuveTypeOCandOD epreuve={epreuve?.data} />;
            case "resolutionProbleme":
                return <EpreuveResolutionProblemOperation epreuve={epreuve?.data} />;
            case "completerEnonces":
                return <EpreuveResolutionProblemeEnonce epreuve={epreuve?.data} />;
            case "dictee":
            case "transcription":
            case "dicteeChronometree":
                return <EpreuveDictee epreuve={epreuve?.data} />;
            case "tsq":
                return <EpreuveTypeTSQ epreuve={epreuve?.data} />;
            // case "operation":
            //     return <h1>Type operation  {console.log(epreuve?.data)}</h1>;

            case null:
                return <EperuveNullType epreuve={epreuve?.data} />;
            default:
                return (
                    <h1>
                        Détails pour le type {type} d'exercice en cours{" "}
                        {console.log(epreuve?.data)}
                    </h1>
                );
        }
    };

    return (
        <Box>
            <Group position="apart" mb={20}>
                <ActionIcon onClick={() => navigate(-1)}>
                    <ArrowLeft />
                </ActionIcon>
                <Button
                    onClick={() => {
                        const path = `/admin/diagnostiques/epreuves/edit/${epreuveId}`;
                        console.log('Navigating to:', path, {
                            state: { epreuve: epreuve?.data }
                        });
                        navigate(path, {
                            state: { epreuve: epreuve?.data }
                        });
                    }}
                    color="green"
                    disabled={isLoading}
                >
                    Modifier l'épreuve
                </Button>
            </Group>

            {isLoading ? (
                <Center>
                    <Loader />
                </Center>
            ) : (
                <Box>{renderEpreuve()}</Box>
            )}
        </Box>
    );
};

export default EpreuveDetailPage;
