import React from 'react';
import { Box, TextInput, Group, ActionIcon, Divider, MultiSelect } from '@mantine/core';
import { X, Plus } from 'tabler-icons-react';

function MissingNumbersEpreuve({ form, index, onRemove }) {
    const isEditing = Boolean(form.values.epreuves[index].id);

    // Generate numbers for selection based on consigne values
    const generateNumberOptions = () => {
        const numbers = form.values.epreuves[index].consigne
            .filter(item => item.value !== "")
            .map(item => Number(item.value));

        if (numbers.length >= 2) {
            const startValue = Math.min(...numbers);
            const endValue = Math.max(...numbers);
            const options = [];

            for (let i = startValue; i <= endValue; i += 5) {
                options.push({
                    value: i.toString(),
                    label: i.toString()
                });
            }
            return options;
        }
        return [];
    };

    // Just store which numbers should be hidden
    const handleHideNumbers = (numbersToHide) => {
        form.setFieldValue(`epreuves.${index}.numbersToHide`, numbersToHide);
    };

    // When adding a new number, update correction_dp with all numbers
    const handleAddNumber = (idx, value) => {
        form.setFieldValue(`epreuves.${index}.consigne.${idx}.value`, value);
        if (value && !isEditing) {
            const allNumbers = generateNumberOptions().map(opt => opt.value);
            form.setFieldValue(`epreuves.${index}.correction_dp`, allNumbers);
        }
    };

    const selectOptions = generateNumberOptions();

    return (
        <Box key={index}>
            <TextInput
                placeholder="Complétez la liste en comptant par 5"
                mb={10}
                radius={10}
                {...form.getInputProps(`epreuves.${index}.titre`)}
            />
            <Group position="apart" align="center" noWrap>
                <Group spacing="xs">
                    {form.values.epreuves[index].consigne.map((item, idx) => (
                        <TextInput
                            key={idx}
                            type="number"
                            placeholder="Nombre"
                            radius={10}
                            w={80}
                            value={item.value}
                            onChange={(e) => handleAddNumber(idx, e.target.value)}
                        />
                    ))}
                    <ActionIcon
                        color="green"
                        onClick={() =>
                            form.insertListItem(`epreuves.${index}.consigne`, {
                                value: "",
                                type: "number",
                            })
                        }
                    >
                        <Plus size="1rem" />
                    </ActionIcon>
                </Group>
                {selectOptions.length > 0 && (
                    <Group spacing="sm" noWrap>
                        <MultiSelect
                            data={selectOptions}
                            placeholder="Sélectionnez les nombres à cacher"
                            radius={10}
                            w={250}
                            value={form.values.epreuves[index].numbersToHide || []}
                            onChange={handleHideNumbers}
                        />
                        <ActionIcon color="red" onClick={() => onRemove(index)}>
                            <X size="1rem" />
                        </ActionIcon>
                    </Group>
                )}
            </Group>
            <Divider my={20} />
        </Box>
    );
}

export default MissingNumbersEpreuve; 